import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import MTable from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// import swal from 'sweetalert' // https://sweetalert.js.org/guides/
import Buttons from '../Buttons.json';
import ViewChildData from './ViewChildData';
import ViewCandidateData from './ViewCandidateData';
var request = require("request-promise")

export default (props) => {
  const [portalAPI] = useState(props.portalAPI)
  const [keycloakAPI] = useState(props.keycloakAPI)
  // Уведомления на встречу
  const [userRole, setUserRole] = useState(null)
  const [userOrgId, setUserOrgId] = useState(props.userOrgId)
  const [buttons, setButtons] = useState(null)
  const [notificationsList, setNotificationsList] = useState(null)
  const [task, setTask] = useState(null)
  const [childId, setChildId] = useState(null)
  const [candidateId, setCandidateId] = useState(null)
  const [currentNotification, setCurrentNotification] = useState(null)
  const [currentCandidate, setCurrentCandidate] = useState(null)

  var prevCandidateSolutionId = null

  useEffect(() => {
    setUserRole(props.userRole)
    setUserOrgId(props.userOrgId)
    console.log("TASK", props.taskType)
    if (props.taskType === "getNotificationsByOrgId") {
      setButtons(Buttons[props.userRole].GetNotificationGridButtons)
      setTask("getNotificationsByOrgId")
      fetchNotificationsByOrgId(props.userOrgId)
    }
  }, [])
  async function fetchNotificationsByOrgId(userOrgId) {
    console.log("FETCH MEET NOTIF-S BY ORG", userOrgId)
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/GetNotificationsByOrgId?orgId=" + userOrgId,
        "method": "GET"
      }
    )
      .then(function (response) {
        let parsedData = JSON.parse(response)
        console.log("NOTIF-S", parsedData)
        setNotificationsList(parsedData)
      })
      .catch(function (error) {
        return console.log("Request error: ", error)
      })
  }
  async function setRead(id) {
    await request(
      {
        "headers": { "content-type": "application/json" },
        "url": portalAPI + "/ChildrenAdoptionApi/api/MeetupRequests/SetRead?requestId=" + id,
        "method": "GET"
      }
    )
      .then(function (response) {
        getUnreadCount(userOrgId, userRole)
        // let parsedData = JSON.parse(response)
        // console.log("CHILD", parsedData)
      })
      .catch(function (error) {
        return console.log("Request error: ", error)
      })
  }
  function getUnreadCount(userOrgId, userRole) {
    props.getUnreadCount(userOrgId, userRole)
  }
  // function parseDate(date){
  //   var newDate = new Date(date)
  //   var dd = String(newDate.getDate()).padStart(2, '0')
  //   var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
  //   var yyyy = newDate.getFullYear()
  //   var convertedDate = dd + '-' + mm + '-' + yyyy
  //   return convertedDate
  // }
  function parseDate(date) {
    var newDate = new Date(date)
    var dd = String(newDate.getDate()).padStart(2, '0')
    var mm = String(newDate.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = newDate.getFullYear()
    var hour = newDate.getHours()
    var minutes = newDate.getMinutes()
    var convertedDate = dd + '-' + mm + '-' + yyyy + '  ' + hour + ':' + minutes
    return convertedDate
  }
  function getStyleByStatus(candidate, index) {
    // console.log("CAND", candidate)
    if (index === 0) {
      // Кандидат 1й в списке
      if (candidate.VisitResult === null) {
        // Кандидат посещает ребенка, решения еще нет
        prevCandidateSolutionId = null
        return ({ backgroundColor: "#AFB3FC" }) // Blue
      }
      else {
        if (candidate.VisitResult.CandidateSolutionId === "46cb0b76-2a82-4e20-9f55-cdfae5393bdf") {
          // Согласие
          prevCandidateSolutionId = candidate.VisitResult.CandidateSolutionId
          return ({ backgroundColor: "#7BFC72" }) // Green
        }
        else if (candidate.VisitResult.CandidateSolutionId === "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66" || candidate.VisitResult.CandidateSolutionId === "00000000-0000-0000-0000-000000000000") {
          // Отказ
          prevCandidateSolutionId = candidate.VisitResult.CandidateSolutionId
          return ({ backgroundColor: "#FB6464" }) // Red
        }
      }
    }
    else {
      // Кандидат 2й и ниже в списке
      if (candidate.VisitResult === null) {
        if (prevCandidateSolutionId === null || prevCandidateSolutionId === "46cb0b76-2a82-4e20-9f55-cdfae5393bdf") {
          // Предыдущий кандидат согласился либо еще не решил
          prevCandidateSolutionId = null
          return ({ backgroundColor: "#F9F784" }) // Yellow
        }
        else if (prevCandidateSolutionId === "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66" || prevCandidateSolutionId === "00000000-0000-0000-0000-000000000000") {
          // Предыдущий кандидат отказался
          prevCandidateSolutionId = null
          return ({ backgroundColor: "#AFB3FC" }) // Blue
        }
      }
      else {
        if (candidate.VisitResult.CandidateSolutionId === "46cb0b76-2a82-4e20-9f55-cdfae5393bdf") {
          // Согласие
          prevCandidateSolutionId = candidate.VisitResult.CandidateSolutionId
          return ({ backgroundColor: "#7BFC72" }) // Green
        }
        else if (candidate.VisitResult.CandidateSolutionId === "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66" || candidate.VisitResult.CandidateSolutionId === "00000000-0000-0000-0000-000000000000") {
          // Отказ
          prevCandidateSolutionId = candidate.VisitResult.CandidateSolutionId
          return ({ backgroundColor: "#FB6464" }) // Red
        }
      }
    }
  }
  function getStatus(visitResult) {
    // console.log("VIS RES", visitResult)
    if (visitResult === null) {
      return ("-")
    }
    else {
      if (visitResult.CandidateSolutionId === "46cb0b76-2a82-4e20-9f55-cdfae5393bdf") {
        return ("Согласие")
      }
      else if (visitResult.CandidateSolutionId === "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66" || visitResult.CandidateSolutionId === "00000000-0000-0000-0000-000000000000") {
        return ("Отказ")
      }
    }
  }
  async function buttonClick(name, notification) {
    console.log("Button", name, notification)
    if (name === "openNotification") {
      // setCandidateId(notification.CandidateRequest.PortalUserId)
      setChildId(notification.ChildId)
      if (userRole === "operator" || userRole === "boardingHouse") {
        await setRead(notification.Id)
      }
      setCurrentNotification(notification)
      setTask("viewNotificationData")
      // setUserRequest(notification)
      setButtons(Buttons[userRole].ViewNotificationDataButtons)
    }
    else if (name === "viewNotificationBack") {
      fetchNotificationsByOrgId(userOrgId)
      setButtons(Buttons[userRole].GetNotificationGridButtons)
      setTask("getNotificationsByOrgId")
    }
    else if (name === "viewCandidateData") {
      setCandidateId(notification.PortalUserId)
      setButtons(Buttons[userRole].ViewCandidateDataButtons)
      setTask("viewCandidateData")
      setCurrentCandidate(notification)
    }
    else if (name === "viewCandidateDataBack") {
      // setChildId(notification.ChildId)
      setTask("viewNotificationData")
      setButtons(Buttons[userRole].ViewNotificationDataButtons)
    }
    else {
      console.log("Button", name)
    }
  }
  // console.log("CAND PROPS", props)
  if (notificationsList !== null && task === "getNotificationsByOrgId") {
    if (Object.keys(notificationsList).length === 0) {
      return (
        <div align="center" style={{ paddingTop: 250, fontSize: 20 }}>
          Список уведомлений пуст
        </div>
      )
    }
    else {
      return (
        notificationsList.map((notification, index) => {
          return (
            <Grid>
              <Card>
                <MTable size="small" >
                  <TableHead style={(notification.Unread === true) ? { backgroundColor: "#FA2D2D" } : { backgroundColor: "#D7E3DD" }}>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>№</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Дата запроса</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Дата окончания</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Количество заявок</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{parseDate(notification.CreatedAt)}</TableCell>
                      <TableCell>{parseDate(notification.Deadline)}</TableCell>
                      <TableCell>{notification.MeetupRequestItems.length}</TableCell>
                    </TableRow>
                  </TableBody>
                </MTable>
                {buttons.map((button, index) =>
                  <Button
                    key={index}
                    name={button.name}
                    variant="outlined"
                    onClick={() => buttonClick(button.name, notification)}
                    style={{
                      margin: 1,
                      fontSize: 11,
                      backgroundColor: button.backgroundColor
                    }}
                  >{button.label}
                  </Button>
                )}
              </Card>
              <br></br>
            </Grid>
          )
        })
      )
    }
  }
  else if (candidateId !== null && task === "viewCandidateData") {
    return (
      <Grid>
        <Card>
          <ViewCandidateData
            portalAPI={portalAPI}
            keycloakAPI={keycloakAPI}
            candidateId={candidateId}
          />
          <MTable size="small" >
            <TableHead style={{ backgroundColor: "#D7E3DD" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Результат встречи</TableCell>
              </TableRow>
            </TableHead>
          </MTable>
          <MTable size="small" >
            <TableHead style={{ backgroundColor: "#D7E3DD" }}>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Дата</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Решение</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Причина отказа</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{currentCandidate.VisitResult === null ? "-" : parseDate(currentCandidate.VisitResult.RegDate)}</TableCell>
                <TableCell>{currentCandidate.VisitResult === null ? "-" : ((currentCandidate.VisitResult.CandidateSolutionId === "0d7921d2-e8ac-40d0-aad6-93ff5bd7be66" || currentCandidate.VisitResult.CandidateSolutionId === "00000000-0000-0000-0000-000000000000") ? "Отказ" : "Согласие")}</TableCell>
                <TableCell>{currentCandidate.VisitResult === null ? "-" : currentCandidate.VisitResult.RefuseReason}</TableCell>
              </TableRow>
            </TableBody>
          </MTable>
          <br></br>
          {buttons.map((button, index) =>
            <Button
              key={index}
              name={button.name}
              variant="outlined"
              onClick={() => buttonClick(button.name, currentCandidate)}
              style={{
                margin: 1,
                fontSize: 11,
                backgroundColor: button.backgroundColor
              }}
            >{button.label}
            </Button>
          )}
        </Card>
      </Grid>
    )
  }
  else if (childId !== null && task === "viewNotificationData") {
    return (
      <Grid>
        <Card>
          <ViewChildData
            portalAPI={portalAPI}
            keycloakAPI={keycloakAPI}
            childId={childId}
            viewAddress={false}
          />
          <Grid>
            <Card>
              <MTable size="small" >
                <TableHead style={{ backgroundColor: "#D7E3DD" }}>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Действие</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>№ очереди</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Район</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>ФИО</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Дата выдачи заключения</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Дата заявки</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Статус</TableCell>
                  </TableRow>
                </TableHead>
                {currentNotification.MeetupRequestItems.map((candidate, index) => (
                  <TableBody>
                    <TableRow style={getStyleByStatus(candidate, index)}>
                      <TableCell>
                        <Button
                          key={index}
                          name="viewCandidateData"
                          variant="outlined"
                          onClick={() => buttonClick("viewCandidateData", candidate)}
                          style={{
                            margin: 1,
                            fontSize: 11,
                            backgroundColor: "#D1FBD1"
                          }}
                        >Открыть
                        </Button>
                      </TableCell>
                      <TableCell style={index === 0 ? { fontWeight: "bold" } : null}>{candidate.OrderNo}</TableCell>
                      <TableCell style={index === 0 ? { fontWeight: "bold" } : null}>{candidate.OrgName}</TableCell>
                      <TableCell style={index === 0 ? { fontWeight: "bold" } : null}>{candidate.CandidateFullName}</TableCell>
                      <TableCell style={index === 0 ? { fontWeight: "bold" } : null}>{parseDate(candidate.CertificateDate)}</TableCell>
                      <TableCell style={index === 0 ? { fontWeight: "bold" } : null}>{parseDate(candidate.CreatedAt)}</TableCell>
                      <TableCell style={index === 0 ? { fontWeight: "bold" } : null}>{getStatus(candidate.VisitResult)}</TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </MTable>
            </Card>
            <br></br>
          </Grid>
          {buttons.map((button, index) =>
            <Button
              key={index}
              name={button.name}
              variant="outlined"
              onClick={() => buttonClick(button.name, currentNotification)}
              style={{
                margin: 1,
                fontSize: 11,
                backgroundColor: button.backgroundColor
              }}
            >{button.label}
            </Button>
          )}
        </Card>
      </Grid>
    )
  }
  else {
    return (
      <div align="center" style={{ paddingTop: 250 }}>
        <CircularProgress />
      </div>
    )
  }
};
